.home_main_section {
  height: calc(100vh - 65px);
  max-height: 900px;
}

/* -----------------------titles--------------------- */
.title_large {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #4d318c;
  font-style: italic;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
}

.title_semibold_medium {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #4d318c;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
}
.title_semibold_small {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #4d318c;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
.subtitle_bold {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  color: #4d318c !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 32px !important;
}

.text_body_medium {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  color: #696969 !important;
  font-style: normal !important;
  /* font-weight: 400 !important; */
  font-size: 18px !important;
  line-height: 28px !important;
}
.hero_image {
  display: block;
  margin: auto;
  width: 70%;
}
.text_body_medium li,
.title_semibold_small li {
  text-align: left !important;
}
.text_body_small_regular {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  color: #696969 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.text_body_small_regular li {
  text-align: left !important;
}
.text_body_xs_regular {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #696969;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

/*--------------------------- cards----------------------------- */
.card_holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  gap: 30px;
  margin: 60px 0;
  flex-wrap: wrap;
}

.card4 {
  background: #f4f4f4;
  /* box-shadow: 0px 8px 30px rgba(37, 49, 109, 0.1); */
  border-radius: 32px;
  /* margin: 128px 0; */
  padding: 32px;
  /* min-height: 420px; */
  box-sizing: border-box;

  /* background image */
  /* background-image: url("../public/images/Boy.svg"); */
  background-repeat: no-repeat;
  background-position: right 40px bottom -2px;
  background-size: 380px;
}

.card6 {
  background: #fff;
  flex: 0 0 calc(33% - 17px);
  padding: 50px 36px;
  border-radius: 16px;
  box-sizing: border-box;
  min-width: 365px;
}
.service_card {
  background: #fff;
  flex: 0 0 calc(33% - 22px);
  padding: 60px 0;
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid #834bff;
  text-align: center;
  cursor: pointer;
  /* transition: box-shadow 0.5s; */
  transition: all 200ms ;
  box-shadow: none;
}

.service_card_icon {
  transition: transform 0.5s;
}

.service_card:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.service_card:hover .service_card_icon {
  animation: shake 0.3s;
}
.card_text_box {
  min-height: 450px;
}

.card_shadow {
  box-shadow: 0px 8px 30px rgba(37, 49, 109, 0.1) !important;
}

/* ------------------------------form and inputs --------------------------------- */

.demo_form_title {
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #4d318c;
  font-style: normal;
  /* font-style: italic; */
  font-weight: 900;
  font-size: 32px;
  line-height: 46px;
}

.message_box {
  padding: 60px 100px;
  box-sizing: border-box;
  background: #f4f4f4;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 26px 10px rgba(124, 124, 124, 0.06);
  border-radius: 32px;
}

.message_form_input_style .MuiOutlinedInput-root {
  padding: 14px 42px 14px 16px !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
.message_form_input_style .MuiOutlinedInput-root:hover fieldset {
  border-color: #834bff !important;
}
.message_form_input_style input,
.demo_form_input_style input,
.demo_form_input_style2 input {
  padding: 0 !important;
}
.demo_form_input_style .MuiOutlinedInput-root {
  padding: 16.5px 22px 15.5px 16px !important;
  background: #f6faff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
/* .demo_form_input_style:hover fieldset {
  border-color: "#969696";
}
.demo_form_input_style2:hover fieldset {
  border-color: "#969696";
} */
.demo_form_input_style2 .MuiOutlinedInput-root {
  padding: 14px 22px 14px 16px !important;
  background: #f6faff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
.demo_form_text_box_style {
  padding: 16.5px 22px 15.5px 16px !important;
  background: #f6faff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
.message_form_textarea_style .MuiOutlinedInput-root {
  padding: 16.5px 42px 15.5px 16px !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
.demo_form_textarea_style .MuiOutlinedInput-root {
  padding: 16.5px 42px 15.5px 16px !important;
  background: #f6faff !important;
  border-radius: 8px !important;
  letter-spacing: 0.32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #696969 !important;
}
.message_form_input_style fieldset,
.message_form_textarea_style fieldset {
  border-color: rgba(0, 0, 0, 0);
}
.demo_form_input_style fieldset,
.demo_form_input_style2 fieldset,
.demo_form_textarea_style fieldset {
  border-color: #cfe3ff;
  /* border-color: rgba(0, 0, 0, 0); */
}

.message_box_input_box_margin {
  margin-top: 20px !important;
}
.message_box_title_margin {
  margin-top: 25px;
}
/* -------------------accrodian------------------------ */
.accrodian_style {
  background: #f4f4f4 !important;
  box-shadow: none !important;
}
.accrodian_style .MuiAccordionSummary-root {
  align-items: start !important;
  padding: 16px 24px !important;
}
.accrodian_style .MuiAccordionSummary-content {
  margin: 0px !important;
}
.accrodian_style .MuiAccordionDetails-root {
  padding: 0px 24px 16px !important;
}
.accrodian_style svg {
  color: #4d318c !important;
  font-size: 28px !important;
}

/* -------------------------buttons-------------------------------- */

.outlined_buttton {
  padding: 10px 24px !important;
  border: 2px solid #cb2027 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  /* margin-top: 40px !important; */
}
.outlined_buttton:hover {
  background: #cb2027 !important;
  color: #fff !important;
}
.contained_buttton {
  padding: 10px 24px !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-weight: 600 !important;
}

.message_button {
  min-width: 350px !important;
}
.demo_form_close_button {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.demo_dialog .MuiDialog-paper {
  border-radius: 32px;
}
.demo_dialog_left {
  background-color: #e2eeff;
  /* background-image: url("../public/images/request-demo-image.svg"); */
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
}

.section_style {
  margin: 150px 0px !important;
}

.left_image_style {
  width: 80%;
  display: block;
}

.idea_card {
  min-height: 320px;
  flex-direction: row;
}

/* ---------------------------show/hide------------------------------------ */
.showForMobileViewOnly {
  display: none !important;
}
.showForTabAndMobileViewOnly {
  display: none !important;
}

/* -------------------------alignment--------------------------------- */
.center {
  text-align: center !important;
}
.left {
  text-align: left !important;
}
/* ---------------------------margins-------------------------- */
.mt10 {
  margin-top: 10px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mb6 {
  margin-bottom: 6px !important;
}
.mb12 {
  margin-bottom: 12px !important;
}
.mb24 {
  margin-bottom: 24px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.margin_left_50 {
  margin-left: 50px;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.card_image {
  width: 70%;
  display: block;
  margin-left: auto;
}
.form_logo_style {
  display: block;
  margin: auto;
  max-width: 155px;
}
@media screen and (max-width: 1536px) {
  .container {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }

  .title_large {
    font-size: 40px;
  }

  .card_holder {
    gap: 15px;
  }

  .showForMobileViewOnly {
    display: none !important;
  }
  .showForTabAndMobileViewOnly {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .home_main_section {
    /* height: calc(100vh - 65px); */
    height: 700px;
  }
  .left_image_style {
    width: 50%;
    margin: auto;
  }
  .card4 {
    background-size: 260px;
  }

  .idea_card {
    min-height: 200px;
  }
  .showForMobileViewOnly {
    display: none !important;
  }
  .showForTabAndMobileViewOnly {
    display: none !important;
  }
  .title_large {
    font-size: 32px;
    line-height: 40px;
  }

  .title_large2 {
    font-size: 28px;
  }

  .title_semibold_medium {
    /* changed */

    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
  }
  .title_semibold_small {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
  }

  .text_body_medium {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .text_body_small_regular {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .text_body_xs_regular {
    font-size: 11px;
  }

  .outlined_buttton,
  .contained_buttton {
    font-size: 13px !important;
    padding: 8px 24px !important;
  }
  .card_image {
    width: 70%;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .left_image_style {
    width: 50%;
    margin: auto;
  }
  .card4 {
    background-size: 200px;
  }
  .card6 {
    flex: 0 0 calc(50% - 15px);
  }

  .hero_image {
    width: 35%;
  }
  .idea_card {
    min-height: 140px;
  }
  .container {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .card_image {
    width: 70%;
  }
  .subtitle_bold {
    font-size: 14px !important;
  }
  .showForMobileViewOnly {
    display: none !important;
  }
  .hideForTabViewOnly {
    display: none !important;
  }
  .showForTabAndMobileViewOnly {
    display: block !important;
  }

  .centerForTabAndMobileViewOnly {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .message_box_input_box_margin {
    margin-top: 20px !important;
  }
  .message_box_title_margin {
    margin-top: 0px;
  }
  /* .card_holder {
    gap: 0px;
  } */
}
@media screen and (max-width: 600px) {
  .left_image_style {
    width: 50%;
    margin: auto;
    margin-bottom: 24px;
  }
  .form_logo_style {
    display: block;
    margin: auto;
    max-width: 140px;
  }
  .hero_image {
    width: 55%;
  }
  .card_image {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
  .card6 {
    flex: 0 0 calc(50% - 15px);
    min-width: 0px;
    max-width: 365px;
    padding: 40px 36px;
  }
  .service_card {
    padding: 40px 0;
    width: 70%;
  }
  .idea_card {
    flex-direction: column-reverse !important;
  }
  .demo_form_title {
    font-size: 24px;
  }
  .home_main_section {
    height: calc(100vh - 55px);
    max-height: 900px;
  }

  .card4 {
    background-size: 0px;
  }
  .home_circle_bg {
    /* background-image: url("../public/images/Splash.svg"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 930px;
    /* animation: spin infinite 20s linear; */
  }
  .container {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .hideForMobileViewOnly {
    display: none !important;
  }
  .showForMobileViewOnly {
    display: block !important;
  }
  .showForTabAndMobileViewOnly {
    display: block !important;
  }

  .centerForMobileViewOnly {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .centerForTabAndMobileViewOnly {
    text-align: center !important;
  }
  .title_large {
    text-align: center;
    color: #4d318c;
    font-style: inherit !important;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
  }

  .title_large2 {
    text-align: center;
    /* font-family: "Poppins", sans-serif;
    margin: 0;
    color: #4d318c;
    font-style: italic;
    font-weight: 900;
    font-size: 32px;
    line-height: 42px; */
  }

  .title_semibold_medium {
    /* changed */
    text-align: center;
    /* font-weight: 700;
    font-size: 18px;
    line-height: 32px; */
    line-height: 24px;
  }
  .title_semibold_small {
    /* changed */
    /* font-size: 14px; */
    text-align: center;
  }
  .subtitle_bold {
    font-size: 12px !important;

    line-height: 20px !important;
    /* text-align: center !important; */
  }
  .text_body_medium {
    /* changed */
    text-align: center;
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .text_body_small_regular {
    text-align: center;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .text_body_xs_regular {
    text-align: center;
  }

  .card_holder {
    flex-direction: column;
    align-items: center;
  }

  .section_style {
    margin: 100px 0px !important;
  }

  .outlined_buttton {
    display: flex !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .message_box {
    padding: 60px 20px;
  }
  .message_button {
    min-width: 250px !important;
  }

  .message_form_input_style input,
  .message_form_textarea_style input,
  .message_form_textarea_style textarea {
    text-align: center !important;
    padding: 0px 16px 0px 16px !important;
  }

  .message_box_input_box_margin {
    margin-top: 20px !important;
  }
  .message_box_title_margin {
    margin-top: 0px !important;
  }
  .margin_left_50 {
    margin-left: 0px;
  }

  .padding_left_for_MobileView {
    padding-left: 25px;
  }

  .accrodian_style svg {
    font-size: 20px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
.grecaptcha-badge {
  visibility: hidden !important;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  /* 10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  } */
  /* 30% {
    transform: translate(3px, 2px) rotate(0deg);
  } */
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  /* 50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  } */
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  /* 80% {
    transform: translate(-1px, -1px) rotate(1deg);
  } */
  /* 90% {
    transform: translate(1px, 2px) rotate(0deg);
  } */
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
